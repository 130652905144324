<template>
  <b-row>
    <b-col md="4">
      <vue-position-sticky :offsetTop="74">
        <b-card-normal title="İptal Politika Tanımlama" :showLoading="show">
          <template v-slot:body>
            <tanimlama
              :politikalar="politikalar"
              @showLoading="show = $event"
              @refresh="handlerGetData"
              :updateData="updateData"
            />
          </template>
        </b-card-normal>
      </vue-position-sticky>
    </b-col>
    <b-col md="8" order-sm="12">
      <b-card-normal title="İptal Politikaları" :showLoading="show">
        <template v-slot:body>
          <list :data="politikalar" @update="handlerUpdate($event)" @refresh="handlerGetData" />
        </template>
      </b-card-normal>
    </b-col>
  </b-row>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import Tanimlama from './component/tanimlama.vue';
import List from './component/list.vue';
import { ref } from '@vue/composition-api';
import store from '@/store';
export default {
  components: {
    BCardNormal,
    Tanimlama,
    List,
  },

  setup() {
    const expo = {};
    expo.show = ref(false);
    expo.politikalar = ref([]);
    expo.updateData = ref({});

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.handlerGetData = async () => {
      expo.show.value = true;
      expo.politikalar.value = [];
      await store.dispatch('iptalPolitikaListele');
      const politika = await store.getters.getIptalPolitikalari;

      politika.forEach((el) => {
        expo.politikalar.value.push({ ...el });
      });

      expo.updateData.value = {};

      expo.show.value = false;
    };

    expo.handlerGetData();

    expo.handlerUpdate = (event) => {
      expo.updateData.value = event;
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
